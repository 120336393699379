import { ProjectProps } from "./interfaces/interfaces";
import TempoistIcon from "assets/tempoist_icon.inline.svg";

export const tempoist: ProjectProps = {
  title: "Tempoist",
  image: TempoistIcon,
  appStoreUrl: "https://apps.apple.com/us/app/tempoist/id1609335829",
  description: "A simple, easy to use metronome",
  detailsUrl: "tempoist",
};

export const getTuned: ProjectProps = {
  title: "GetTuned",
  imageUrl:
    "https://is4-ssl.mzstatic.com/image/thumb/Purple124/v4/1f/53/bc/1f53bc7c-3a7f-0495-e877-ffe4f0de393b/AppIcon-1x_U007emarketing-0-8-0-85-220.png/1024x1024bb.png",
  appStoreUrl: "https://apps.apple.com/us/app/tunelyzer/id1440592987",
  description: "Tuner for your instruments",
  detailsUrl: "gettuned",
};

export const allProjectData: ProjectProps[] = [tempoist, getTuned];
