import React from "react";
import { Layout } from "src/layouts";
import { tempoist } from "src/utilities/projectData";
import {
  AppImageSection,
  Container,
  ContentSection,
  ImageContainer,
  LinksContainer,
} from "src/components/ProductDetails";
import AppStore from "assets/AppStore.inline.svg";
import GitHub from "assets/GitHub_Logo.inline.svg";
import Tempoist1 from "assets/Tempoist1.inline.png";
import Tempoist2 from "assets/Tempoist2.inline.png";
import Tempoist3 from "assets/Tempoist3.inline.png";

const Tempoist = () => {
  return (
    <Layout>
      <Container>
        <article>
          <h1>{tempoist.title}</h1>
          <ImageContainer>
            {tempoist.image && (
              <tempoist.image alt="App icon" className="appIcon" />
            )}
            {tempoist.imageUrl && (
              <img src={tempoist.imageUrl} alt="App icon" className="appIcon" />
            )}
            <p>{tempoist.description}</p>
            <LinksContainer>
              <a href={tempoist.appStoreUrl}>
                <AppStore alt="Download on the AppStore" />
              </a>
              <a href="https://github.com/carlh/tempoist">
                <GitHub
                  alt="View code on GitHub"
                  style={{ borderRadius: 10, border: "1px solid #fff8" }}
                />
              </a>
            </LinksContainer>
          </ImageContainer>
          <ContentSection>
            <h2>Description</h2>
            <p>
              A metronome plays a fundamental part in any musician's practice
              routine. Dancers use them to practice timing thier steps. Runners
              use them to ensure that they're running at a healthy cadence.
              Tempoist brings a simple, clean design to this essential tool.
            </p>
            <AppImageSection>
              <img src={Tempoist1} alt="Product image 1" className="appImage" />
              <img src={Tempoist2} alt="Product image 2" className="appImage" />
              <img src={Tempoist3} alt="Product image 3" className="appImage" />
            </AppImageSection>
          </ContentSection>
          <ContentSection>
            <h2>Privacy Policy</h2>
            <p>
              No personal data is collected and I'm not doing any telemetry
              monitoring. Read the full privacy policy{" "}
              <a href="https://www.iubenda.com/privacy-policy/42809349">here</a>
              .
            </p>
          </ContentSection>
          <ContentSection>
            <h2>Support</h2>
            <p>
              If you've found a bug, please file an issue on{" "}
              <a href="https://github.com/carlh/tempoist/issues">GitHub</a>. If
              you have general feedback or questions, start a{" "}
              <a href="https://github.com/carlh/tempoist/discussions">
                discussion
              </a>{" "}
              or send me an{" "}
              <a href="mailto:support@carlhinkle.com?subject=Tempoist Support">
                email
              </a>
              .
            </p>
          </ContentSection>
        </article>
      </Container>
    </Layout>
  );
};

export default Tempoist;
