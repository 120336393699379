import styled from "styled-components";
import { below } from "src/utilities";

export const Container = styled.article`
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .appIcon {
    width: 300px;
    height: 300px;
    border-radius: 30px;
    box-shadow: 1px 1px 10px 1px #0003;
  }
`;

export const LinksContainer = styled.div`
  margin: 3em;
  display: flex;
  gap: 40px;

  a {
    height: 40px;
  }
`;

export const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2em;

  ${below.medium`
    align-items: center;
  `}
`;

export const AppImageSection = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-around;
  .appImage {
    height: 400px;
    width: auto;
  }

  ${below.medium`
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    width: auto;
  `}
`;
